import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import Select from 'react-select'

import { httpOk, GET, POST, PUT } from 'helpers/api'

function Index() {
	const { t } = useTranslation()

	const [data, setData] = useState({
		'id': "",
		'name': ""
	})
	const [paymentTypes, setPaymentTypes] = useState([])
	const [deliveryRules, setDeliveryRules] = useState([])
	const [orderDeliveryTypes, setOrderDeliveryTypes] = useState([])

	async function searchWithFilter() {
		var filterUrl = `/chain/pos-orders`

		var response = await GET(filterUrl, {}, { loader: true })
		if (httpOk(response)) setData(response.data);
	}

	async function createUpdate(e) {
		e.preventDefault()

		var response;
		if (data.id) {
			response = await PUT('/chain/pos-orders', data, { loader: true })
		} else {
			response = await POST('/chain/pos-orders', data, { loader: true })
			if (httpOk(response)) searchWithFilter()
		}

		if (httpOk(response)) { }
	}

	async function getPaymentTypes() {
		const response = await GET('/helper/payment-types')
		if (httpOk(response)) setPaymentTypes(response.data)
	}

	async function getDeliveryRules() {
		const response = await GET('/helper/delivery-rules')
		if (httpOk(response)) setDeliveryRules(response.data)
	}

	async function getOrderTypeSubs() {
		const response = await GET('/helper/order-type-subs')
		if (httpOk(response)) setOrderDeliveryTypes(response.data)
	}

	useEffect(() => {
		searchWithFilter()
		getPaymentTypes()
		getDeliveryRules()
		getOrderTypeSubs()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between">
				<div className="d-flex text-truncate">
					<h4 className="vertical-center">
						{t('delivery')}
					</h4>
				</div>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="row">
						<div className="col-md-8">
							<div className="form-group">
								<label>{t('name2')}</label>
								<input type="text" className="form-control" name="name"
									autoComplete="off"
									value={data.name ?? ''}
									onChange={(e) => setData({ ...data, 'name': e.target.value })} />
							</div>
							<hr />

							<div className="form-group w-100">
								<label>{t('payment_types')}</label>
								<Select
									isMulti
									options={paymentTypes}
									value={data.payment_types}
									onChange={(options) => setData({ ...data, 'payment_types': options })}
									placeholder=""
									noOptionsMessage={() => t('list_empty')}
									getOptionLabel={(option) => option.name}
									getOptionValue={(option) => option.id}
								/>
							</div>
							<div className="form-group w-100">
								<label>{t('default')}</label>
								<Select
									options={paymentTypes}
									value={paymentTypes.find(option => option.payment_type_id === data.payment_type_id) || ''}
									onChange={(option) => setData({ ...data, 'payment_type_id': option.payment_type_id })}
									placeholder=""
									noOptionsMessage={() => t('list_empty')}
									getOptionLabel={(option) => option.name}
									getOptionValue={(option) => option.payment_type_id}
								/>
							</div>

							<div className="form-group w-100">
								<label>{t('order_types')}</label>
								<Select
									options={orderDeliveryTypes}
									value={orderDeliveryTypes.find(option => option.id === data.order_type_sub_id) || ''}
									onChange={(option) => setData({ ...data, 'order_type_sub_id': option ? option.id : null })}
									placeholder=""
									noOptionsMessage={() => t('list_empty')}
									getOptionLabel={(option) => option.name}
									getOptionValue={(option) => option.id}
								/>
							</div>

							<div className="form-group w-100">
								<label>{t('delivery_rules')}<span className="required-mark">*</span></label>
								<Select
									options={deliveryRules}
									value={deliveryRules.find(option => option.id === data.delivery_rule_id) || ''}
									onChange={(option) => setData({ ...data, 'delivery_rule_id': option ? option.id : null })}
									placeholder=""
									noOptionsMessage={() => t('list_empty')}
									getOptionLabel={(option) => option.name}
									getOptionValue={(option) => option.id}
								/>
							</div>

							<div className="d-flex justify-content-between mb-2">
								<div className="fw-500 vertical-center">
									{t('is_send_location')}
								</div>
								<div className="form-check form-switch form-switch-lg">
									<input type="checkbox" className="form-check-input"
										name="is_send_location"
										checked={data.is_auto_confirmed ? true : false}
										onChange={(e) => setData({ ...data, 'is_auto_confirmed': e.target.checked })} />
								</div>
							</div>
						</div>
					</div>

					<div className="btn-wrapper gap-2">
						<Link className="btn btn-warning btn-rounded btn-wide" to="/external-integrations">
							{t('cancel')}
						</Link>
						<button type="button" className="btn btn-primary btn-rounded btn-wide"
							onClick={(e) => createUpdate(e)}>
							{t('save')}
						</button>
					</div>
				</div>
			</div>
		</>
	)
}

export default Index