import React from 'react'
import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps';

function LoginMap() {

	const defaultState = {
		center: [41.347891, 69.184860],
		zoom: 15,
		controls: ["zoomControl"]
	};

	return (
		<>
			<YMaps
				style={{ height: '99vh' }}
				query={{
					load: "package.full",
					apikey: 'b5de12cc-6217-4b13-9078-2e99c84df330'
				}}>
				<Map
					defaultOptions={{
						copyrightUaVisible: false,
						yandexMapDisablePoiInteractivity: true,
					}}
					style={{ height: '99vh' }}
					defaultState={defaultState}
				>
					<Placemark geometry={[41.347891, 69.184860]} />
				</Map>
			</YMaps>
		</>
	)
}

export default LoginMap