import React, { Fragment, useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Modal } from 'react-bootstrap'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import RightbarProduct from './RightbarProduct'
import RightbarProducts from './RightbarProducts'
import { formatMoney } from "helpers/helpers";
import { httpOk, GET, PUT, POST, DELETE } from 'helpers/api'

function Index() {
	const { t } = useTranslation()
	const { id } = useParams()

	const [data, setData] = useState({})
	const [selectedItem, setSelectedItem] = useState({})
	const [modalMenu, setModalMenu] = useState(false)
	const [modalUpdate, setModalUpdate] = useState(false)
	const [modalProduct, setModalProduct] = useState(false)
	const [showRightBar, setShowRightBar] = useState(false)

	async function searchWithFilter() {
		var filterUrl = `/external-menu/${id}`

		var response = await GET(filterUrl, {}, { loader: true })
		if (httpOk(response)) {
			setData(response.data);
		}
	}

	async function createUpdate(e) {
		e.preventDefault()

		var selectedItemCopy = { ...selectedItem }
		selectedItemCopy.external_group_id = id

		var response
		if (!selectedItemCopy.id) {
			response = await POST(`/external-menu`, selectedItemCopy, { loader: true })
		} else {
			response = await PUT(`/external-menu`, selectedItemCopy, { loader: true })
		}

		if (httpOk(response)) {
			var dataCopy = { ...data }

			var foundIndex = dataCopy.menus.findIndex(obj => obj.id === selectedItemCopy.id)
			if (foundIndex >= 0) {
				dataCopy.menus[foundIndex]['name'] = selectedItemCopy.name
				setData(dataCopy)
			}

			toggleModalMenu()
			if (!selectedItemCopy.id) searchWithFilter()
		}
	}

	async function updateAllProducts(e) {
		e.preventDefault()

		var selectedItemCopy = { ...selectedItem }

		var response = await PUT(`/external-menu-all`, selectedItemCopy, { loader: true })

		if (httpOk(response)) searchWithFilter()
	}

	function toggleExternalMenu(index) {
		var dataCopy = { ...data }
		dataCopy.menus[index]['active'] = !dataCopy.menus[index]['active']

		setData(dataCopy)
	}

	function toggleModalMenu(bool = false, item = {}, index) {
		item.index = index
		setSelectedItem(item)
		setModalMenu(bool)
	}

	function toggleModalUpdate(bool = false) {
		var selectedItemCopy = { ...selectedItem }
		selectedItemCopy.name = false
		selectedItemCopy.price = false
		selectedItemCopy.image = false

		setSelectedItem(selectedItemCopy)
		setModalUpdate(bool)
	}

	function toggleModalProduct(bool = false, item = {}, index) {
		item.index = index
		setSelectedItem(item)
		setModalProduct(bool)
	}

	async function toggleRightbar(bool = false, item = {}) {
		console.log(item)
		setSelectedItem(item)
		setShowRightBar(bool)
	}

	async function updateProduct(e) {
		e.preventDefault()
		var selectedItemCopy = { ...selectedItem }
		selectedItemCopy.external_group_id = id
		var response = await PUT(`/external-menu-product`, selectedItemCopy, { loader: true })

		if (httpOk(response)) {
			var dataCopy = { ...data }

			var foundIndex = dataCopy.menus.findIndex(obj => obj.id === selectedItem.external_menu_id)
			if (foundIndex >= 0) {
				dataCopy.menus[foundIndex]['products'][selectedItem.index]['name'] = selectedItem.name
				dataCopy.menus[foundIndex]['products'][selectedItem.index]['sale_price'] = selectedItem.sale_price
				dataCopy.menus[foundIndex]['products'][selectedItem.index]['description'] = selectedItem?.description
				dataCopy.menus[foundIndex]['products'][selectedItem.index]['unit'] = selectedItem?.unit
				dataCopy.menus[foundIndex]['products'][selectedItem.index]['weight'] = selectedItem?.weight
				dataCopy.menus[foundIndex]['products'][selectedItem.index]['image'] = selectedItem?.image
				setData(dataCopy)
				toggleModalProduct()
			}
		}
	}

	async function addProduct(item) {
		var response = await POST('/external-menu-product', {
			'product_id': item.product_id,
			'external_menu_id': selectedItem.id,
			'external_group_id': id,
		}, { loader: true, notification: true })
		if (httpOk(response)) {
			var dataCopy = { ...data }
			var index = dataCopy.menus.findIndex(el => el.id === selectedItem.id)
			dataCopy.menus[index]['products'].push(response.data)
			dataCopy.menus[index]['count_products'] = dataCopy.menus[index]['products'].length
			setData(dataCopy)
		}
	}

	async function deleteProduct(item, menuIndex, productIndex) {
		var response = await DELETE('/external-menu-product', {
			'id': item.id,
		}, { loader: true, notification: true })

		if (httpOk(response)) {
			var dataCopy = { ...data };
			var productsCopy = [...dataCopy.menus[menuIndex].products]; // Копируем массив продуктов
			productsCopy.splice(productIndex, 1); // Удаляем продукт
			dataCopy.menus[menuIndex].products = productsCopy;

			setData(dataCopy)
		}
	}

	async function handleDragEnd(result) {
		const { type } = result;

		if (type === 'menu') {
			handleMenuDragEnd(result);
		}

		// Если это перетаскивание для продуктов
		if (type === 'product') {
			handleProductDragEnd(result);
		}
	};

	async function handleMenuDragEnd(result) {
		const { source, destination } = result;

		// Если нет места назначения, не делаем ничего
		if (!destination) return;

		// Если позиция не изменилась
		if (
			source.index === destination.index &&
			source.droppableId === destination.droppableId
		) {
			return;
		}

		// Перемещаем элементы внутри массива
		const updatedMenus = Array.from(data.menus);
		const [movedItem] = updatedMenus.splice(source.index, 1); // Удаляем элемент из исходной позиции
		updatedMenus.splice(destination.index, 0, movedItem); // Вставляем на новую позицию

		// Обновляем данные
		setData({ ...data, 'menus': updatedMenus });

		await PUT('/external-menu-order-number', {
			'id': movedItem.id,
			'order_number': destination.index + 1,
		}, { loader: true })
	};

	async function handleProductDragEnd(result) {
		const { source, destination } = result;

		// Если нет места назначения, не делаем ничего
		if (!destination) return;

		// Если позиция не изменилась
		if (
			source.index === destination.index &&
			source.droppableId === destination.droppableId
		) {
			return;
		}

		// Find the source and destination menus
		const sourceMenuIndex = data.menus.findIndex(menu => menu.id === parseInt(source.droppableId));
		const destinationMenuIndex = data.menus.findIndex(menu => menu.id === parseInt(destination.droppableId));

		if (sourceMenuIndex === -1 || destinationMenuIndex === -1) return; // Menu not found

		const sourceMenu = data.menus[sourceMenuIndex];
		const destinationMenu = data.menus[destinationMenuIndex];

		const sourceProducts = [...sourceMenu.products];
		const destinationProducts = [...destinationMenu.products];

		// Handle reordering or moving products
		const [movedProduct] = sourceProducts.splice(source.index, 1);

		// If moving to a different menu, add the product to the destination menu
		if (sourceMenuIndex !== destinationMenuIndex) {
			destinationProducts.splice(destination.index, 0, movedProduct);
		} else {
			// If within the same menu, just reorder
			sourceProducts.splice(destination.index, 0, movedProduct);
		}

		// Update the menus with the new products
		const updatedMenus = [...data.menus];
		updatedMenus[sourceMenuIndex] = { ...sourceMenu, 'products': sourceProducts };
		updatedMenus[destinationMenuIndex] = { ...destinationMenu, 'products': destinationProducts };

		// Update the state with the updated menus
		setData({ ...data, 'menus': updatedMenus });

		await PUT('/external-menu-product-order-number', {
			'id': movedProduct.id,
			'order_number': destination.index + 1,
		}, { loader: true })
	};

	useEffect(() => {
		searchWithFilter()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between">
				<div className="d-flex text-truncate">
					<h4 className="vertical-center">
						{t('external_menu')}
					</h4>
					<div className="vertical-center mt-1">
						<i className="uil uil-angle-double-right fz-20"></i>
					</div>
					<div className="vertical-center mt-1">
						<h6>{data?.group?.name}</h6>
					</div>
				</div>
				<div className="d-flex gap-2">
					<div className="btn btn-primary"
						onClick={() => toggleModalUpdate(true)}>
						<i className="uil uil-redo"></i>
					</div>
					<div className="btn btn-primary"
						onClick={() => toggleModalMenu(true)}>
						{t('create')}
					</div>
				</div>
			</div>

			<div className="card">
				<div className="card-body">
					<DragDropContext onDragEnd={handleDragEnd}>
						<Droppable droppableId="menu" type="menu">
							{(provided) => (
								<div {...provided.droppableProps} ref={provided.innerRef}>
									{data?.menus?.map((item, index) => (
										<Draggable key={item.id} draggableId={`${item.id}`} index={index}>
											{(provided, snapshot) => (
												<div className="mb-3">
													<div
														ref={provided.innerRef}
														{...provided.draggableProps}
														{...provided.dragHandleProps}
														className={`external-menu ${snapshot.isDragging ? "dragging" : ""}`}>
														<div className="d-flex" onClick={() => toggleExternalMenu(index)}>
															<div className="external-menu-arrow">
																{item.active ?
																	<i className="uil uil-arrow-down"></i>
																	:
																	<i className="uil uil-arrow-right"></i>
																}
															</div>
															<div className="external-menu-text gap-2">
																<span>{item.name}</span>
																<span className={`${item.count_products > 0 ? '' : 'text-danger'}`}>
																	({item.count_products})
																</span>
															</div>
														</div>
														<div style={{
															'height': '100%',
															'display': 'flex',
															'marginLeft': 'auto',
														}}>
															<div className="external-menu-icons"
																onClick={() => toggleRightbar(true, item)}>
																<i className="uil-plus"></i>
															</div>
															<div className="external-menu-icons"
																onClick={() => toggleModalMenu(true, item)}>
																<i className="uil-pen"></i>
															</div>
															<div className="external-menu-icons d-none">
																<i className="uil-expand-arrows-alt"></i>
															</div>
														</div>
													</div>
													{item.active &&
														<div className="table-responsive" style={{ 'minHeight': 0 }}>
															<table className="table table-bordered mb-0">
																<thead>
																	<tr>
																		<th style={{ 'minWidth': '33px' }} className="text-center">#</th>
																		<th style={{ 'width': '15%' }} className="td-ellipsis">{t('name2')}</th>
																		<th style={{ 'width': '15%' }} className="td-ellipsis">{t('system_name2')}</th>
																		<th style={{ 'width': '5%' }} className="text-center">{t('image2')}</th>
																		<th style={{ 'width': '5%' }} className="text-center">{t('description')}</th>
																		<th style={{ 'width': '5%' }} className="text-center">{t('weight')}</th>
																		<th style={{ 'width': '5%' }} className="text-center text-nowrap">{t('unit')}</th>
																		<th style={{ 'width': '5%' }} className="text-center">{t('status')}</th>
																		<th style={{ 'width': '15%' }} className="text-center">{t('fiscalization')}</th>
																		<th style={{ 'width': '15%' }} className="text-end td-ellipsis" title={t('price')}>{t('price')}</th>
																		<th style={{ 'width': '15%' }} className="text-end td-ellipsis" title={t('sale_price')}>{t('sale_price')}</th>
																		<th style={{ 'minWidth': '33px' }} className="text-center"></th>
																		<th style={{ 'minWidth': '33px' }} className="text-center"></th>
																	</tr>
																</thead>
																<Droppable droppableId={`${item.id}`} type="product">
																	{(provided) => (
																		<tbody ref={provided.innerRef} {...provided.droppableProps}>
																			{item?.products?.map((product, index2) => (
																				<Draggable key={product.id} draggableId={`${product.id}`} index={index2}>
																					{(provided, snapshot) => (
																						<tr
																							ref={provided.innerRef}
																							{...provided.draggableProps}
																							className={`product-row ${snapshot.isDragging ? "dragging" : ""}`}>
																							<td style={{ 'minWidth': '33px' }} className="text-center">
																								{index2 + 1}
																							</td>
																							<td style={{ 'width': '15%' }} className="text-primary cursor"
																								onClick={() => toggleModalProduct(true, product, index2)}>
																								<span>{product.name}</span>
																							</td>
																							<td style={{ 'width': '15%' }}>{product.system_name}</td>
																							<td style={{ 'width': '10%' }} className="text-center">
																								{product.image ? (
																									<span className="bg-primary bg-border"></span>
																								) : (
																									<span className="bg-danger bg-border"></span>
																								)}
																							</td>
																							<td style={{ 'width': '5%' }} className="td-ellipsis text-center">
																								{product.description ? (
																									<span className="bg-primary bg-border"></span>
																								) : (
																									<span className="bg-danger bg-border"></span>
																								)}
																							</td>
																							<td style={{ 'width': '5%' }} className="text-center">
																								{product.weight ? (
																									<span className="bg-primary bg-border"></span>
																								) : (
																									<span className="bg-danger bg-border"></span>
																								)}
																							</td>
																							<td style={{ 'width': '5%' }} className="text-center">
																								{product.unit ? (
																									<span className="bg-primary bg-border"></span>
																								) : (
																									<span className="bg-danger bg-border"></span>
																								)}
																							</td>
																							<td style={{ 'width': '5%' }} className="text-center">
																								{product.status ? (
																									<span className="bg-primary bg-border"></span>
																								) : (
																									<span className="bg-danger bg-border"></span>
																								)}
																							</td>
																							<td style={{ 'width': '15%' }} className="td-ellipsis text-center">
																								{product.fiscal_code || <span className="bg-danger bg-border"></span>}
																							</td>
																							<td style={{ 'width': '15%' }} className="text-nowrap text-end">
																								{formatMoney(product.original_price)}
																							</td>
																							<td style={{ 'width': '15%' }} className="text-nowrap text-end">
																								{formatMoney(product.sale_price)}
																							</td>
																							<td style={{ 'minWidth': '33px' }}
																								onClick={() => deleteProduct(product, index, index2)}>
																								<div className="table-action-button table-action-danger-button">
																									<i className="uil-times"></i>
																								</div>
																							</td>
																							<td style={{ 'minWidth': '33px' }}
																								{...provided.dragHandleProps}>
																								<div className="table-action-button table-action-primary-button">
																									<i className="uil-expand-arrows-alt"></i>
																								</div>
																							</td>
																						</tr>
																					)}
																				</Draggable>
																			))}
																			{provided.placeholder}
																		</tbody>
																	)}
																</Droppable>
															</table>
														</div>
													}
												</div>
											)}
										</Draggable>
									))}
									{provided.placeholder}
								</div>
							)}
						</Droppable>
					</DragDropContext>
				</div>
			</div>

			{/* MODAL MENU */}
			<Modal show={modalMenu} animation={false} centered
				dialogClassName="update-modal-width" backdrop="static"
				onHide={() => toggleModalMenu()}>
				<Modal.Header closeButton>
					<Modal.Title>
						{!selectedItem.id ?
							<>{t('create')}</>
							:
							<>{t('edit')}</>
						}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={createUpdate} autoComplete="off">
						<div className="form-group">
							<label>{t('name2')}</label>
							<input type="text" className="form-control" name="name"
								value={selectedItem.name ?? ""}
								autoFocus
								onChange={(e) => setSelectedItem({ ...selectedItem, 'name': e.target.value })} />
						</div>

						<div className="fz-16">
							<div className="d-flex flex-column">
								<span>* Название без кавычек</span>
								<span>* Название на русском языке</span>
							</div>
						</div>

						<div className="d-flex gap-2 w-100 mt-3">
							<button type="button" className="btn btn-outline-warning w-100"
								onClick={() => toggleModalMenu()}>
								{t('cancel')}
							</button>
							<button type="submit" className="btn btn-primary w-100">
								{t('save')}
							</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			{/* MODAL MENU */}

			{/* MODAL UPDATE */}
			<Modal show={modalUpdate} animation={false}
				centered backdrop="static"
				onHide={() => toggleModalUpdate()}>
				<Modal.Header closeButton>
					<Modal.Title>{t('refresh')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={updateAllProducts} autoComplete="off">
						<div className="form-check">
							<input id="name" className="form-check-input" type="checkbox"
								checked={selectedItem?.name}
								onChange={(e) => setSelectedItem({ ...selectedItem, 'name': e.target.checked })}
							/>
							<label className="form-check-label" htmlFor="name">
								{t('name2')} & {t('description')}
							</label>
						</div>
						<div className="form-check">
							<input id="price" className="form-check-input" type="checkbox"
								checked={selectedItem?.price}
								onChange={(e) => setSelectedItem({ ...selectedItem, 'price': e.target.checked })}
							/>
							<label className="form-check-label" htmlFor="price">
								{t('sale_price')}
							</label>
						</div>
						<div className="form-check">
							<input id="image" className="form-check-input" type="checkbox"
								checked={selectedItem?.image}
								onChange={(e) => setSelectedItem({ ...selectedItem, 'image': e.target.checked })}
							/>
							<label className="form-check-label" htmlFor="image">
								{t('image')}
							</label>
						</div>

						<div className="d-flex gap-2 w-100 mt-3">
							<button type="button" className="btn btn-outline-warning w-100"
								onClick={() => toggleModalUpdate()}>
								{t('cancel')}
							</button>
							<button type="submit" className="btn btn-primary w-100">
								{t('save')}
							</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			{/* MODAL UPDATE */}

			<RightbarProduct
				showRightBar={modalProduct}
				setShowRightBar={setModalProduct}
				toggleModalProduct={toggleModalProduct}
				selectedItem={selectedItem}
				setSelectedItem={setSelectedItem}
				updateProduct={updateProduct}>
			</RightbarProduct>

			<RightbarProducts
				showRightBar={showRightBar}
				setShowRightBar={setShowRightBar}
				addProduct={addProduct}>
			</RightbarProducts>
		</>
	)
}

export default Index