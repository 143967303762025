import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { Dropdown, Modal } from 'react-bootstrap'
import DatePicker from "react-datepicker"

import FilterSearch from 'pages/parts/FilterSearch'
import FilterPagination from 'pages/parts/FilterPagination'

import { formatMoney, formatDate, returnSign } from "helpers/helpers"
import { GET, httpOk } from 'helpers/api'

function Index() {
	const { t } = useTranslation()
	const date = new Date()

	const [data, setData] = useState({})
	const [modalInfo, setModalInfo] = useState(false)
	const [payments, setPayments] = useState([])
	const [filterDataExist, setFilterDataExist] = useState(false)
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	const [filterData, setFilterData] = useState({
		start_date: formatDate(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd'),
		end_date: formatDate(new Date(), 'yyyy-MM-dd'),
	})
	const [pagination, setPagination] = useState({
		url: '/partner/transactions',
		response: null
	})

	async function searchWithFilter() {
		var filterDataCopy = { ...filterData }
		var filterUrl = "/admin/payment-history"
		var urlParams = ""
		checkFilter()

		if (filterDataCopy.start_date)
			urlParams += returnSign(urlParams) + 'start_date=' + filterDataCopy.start_date
		if (filterDataCopy.end_date)
			urlParams += returnSign(urlParams) + 'end_date=' + filterDataCopy.end_date
		if (filterDataCopy.page)
			urlParams += returnSign(urlParams) + 'page=' + filterDataCopy.page

		if (`/admin/payment-history${urlParams}` !== filterUrl)
			setFilterDataExist(true)

		filterUrl += urlParams

		const response = await GET(filterUrl, {}, { loader: true })
		if (httpOk(response)) {
			setData(response.data)
			setPagination({ ...pagination, 'url': filterUrl, 'response': response.data })
		}

		setDropdownIsOpen(false)
	}

	async function toggleModalInfo(bool) {
		if (bool) {
			const response = await GET('/admin/payment-history-group', filterData, { loader: true })
			if (httpOk(response)) setPayments(response.data)
		}

		setModalInfo(bool)
	}

	function checkFilter() {
		if (filterData.status === null) {
			setFilterDataExist(false)
		}
	}

	function toggleDropdown() {
		setDropdownIsOpen(!dropdownIsOpen)
	}

	async function paginate(page) {
		setFilterData({ ...filterData, 'page': page })
	}

	useEffect(() => {
		searchWithFilter()
	}, [filterData.search, filterData.page]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between">
				<div className="vertical-center">
					<h4>{t('payment_history')} [{data.total}]</h4>
				</div>
				<div className="d-flex">
					<button className="btn-rounded btn btn-primary"
						onClick={() => toggleModalInfo(true)}>
						{t('information')}
					</button>
				</div>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="d-flex justify-content-between mb-2">
						<div className="d-flex flex-wrap gap-2">
							<div>
								<FilterSearch
									filterData={filterData}
									setFilterData={setFilterData}>
								</FilterSearch>
							</div>
							<div>
								<Dropdown onToggle={toggleDropdown} show={dropdownIsOpen} className="h-fit-content">
									<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100">
										{t('filter')}
									</Dropdown.Toggle>

									<Dropdown.Menu className="dropdown-filter">
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('period')}</label>
												<div className="calendar-input">
													<DatePicker
														className="form-control"
														dateFormat="dd.MM.yyyy"
														selected={filterData.start_date ? new Date(filterData.start_date) : ''}
														onChange={(date) => setFilterData({ ...filterData, 'start_date': formatDate(date, 'yyyy-MM-dd') })} />
													<i className="uil uil-calendar-alt"></i>
												</div>
											</div>
											<div className="form-group col-md-6">
												<label className="invisible">.</label>
												<div className="calendar-input">
													<DatePicker
														className="form-control"
														dateFormat="dd.MM.yyyy"
														selected={filterData.end_date ? new Date(filterData.end_date) : ''}
														onChange={(date) => setFilterData({ ...filterData, 'end_date': formatDate(date, 'yyyy-MM-dd') })} />
													<i className="uil uil-calendar-alt"></i>
												</div>
											</div>
										</div>
										<div className="row mt-3">
											<div className="col-md-12">
												<div className="d-flex justify-content-end gap-2">
													<button className="btn btn-secondary"
														onClick={() => setDropdownIsOpen(false)}>
														{t('cancel')}
													</button>
													<button className="btn btn-primary"
														onClick={() => searchWithFilter()}>
														{t('apply')}
													</button>
												</div>
											</div>
										</div>
									</Dropdown.Menu>
								</Dropdown>
							</div>
						</div>
						<div className="vertical-center">
							<h5 className="d-flex justify-content-between gap-3">
								<b>{t('amount')}:</b> {formatMoney(data.total_amount)}
							</h5>
						</div>
					</div>

					{filterDataExist &&
						<div className="filter-block">
							<div className="filter">
								{(filterData.start_date || filterData.end_date) &&
									<span className="me-2">
										<b>{t('period')}: </b>
										{filterData.start_date &&
											<span>{formatDate(filterData.start_date)}</span>
										}
										{(filterData.start_date && filterData.end_date) &&
											<b> - </b>
										}
										{filterData.end_date &&
											<span>{formatDate(filterData.end_date)}</span>
										}
									</span>
								}
								{filterData.search &&
									<span className="me-2">
										<b>{t('search')}: </b>
										<span>{filterData.search}</span>
									</span>
								}
							</div>
						</div>
					}

					<div className="table-responsive">
						<table className="table table-bordered mb-0">
							<thead>
								<tr>
									<th style={{ 'minWidth': '33px' }} className="text-center">#</th>
									<th style={{ 'width': '20%' }}>{t('pos')}</th>
									<th style={{ 'width': '15%' }}>{t('login')}</th>
									<th style={{ 'width': '25%' }}>{t('note')}</th>
									<th style={{ 'width': '10%' }} className="text-center">{t('date')}</th>
									<th style={{ 'width': '10%' }} className="text-center">{t('type')}</th>
									<th style={{ 'width': '10%' }} className="text-end">{t('tariff')}</th>
									<th style={{ 'width': '10%' }} className="text-end">{t('amount')}</th>
								</tr>
							</thead>
							<tbody>
								{data?.data?.map((item, index) => (
									<tr key={index}>
										<td className="text-center">{item.row_number}</td>
										<td className="td-ellipsis">{item.pos_name}</td>
										<td className="td-ellipsis">{item.login}</td>
										<td className="td-ellipsis" title={item.note}>{item.note}</td>
										<td className="text-center">{formatDate(item.created_at, 'dd.MM.yy HH:mm')}</td>
										<td className="text-center">{item.payment_type}</td>
										<td className="text-end">{formatMoney(item.tariff)}</td>
										<td className="text-end">{formatMoney(item.amount)}</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>

					{pagination.response &&
						<FilterPagination
							pagination={pagination}
							paginate={paginate}>
						</FilterPagination>
					}
				</div>
			</div>

			{/* MODAL PAYMENT HISTORY*/}
			<Modal show={modalInfo} animation={false} centered
				dialogClassName="update-modal-width" backdrop="static"
				onHide={() => setModalInfo(false)}>
				<Modal.Header closeButton>
					<Modal.Title>
						История
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="table-responsive">
						<table className="table table-bordered mb-0">
							<thead>
								<tr>
									<th className="text-nowrap">{t('type')}</th>
									<th className="text-nowrap text-end">{t('amount')}</th>
								</tr>
							</thead>
							<tbody>
								{payments?.data?.map((item, index) => (
									<tr key={index}>
										<td className="td-ellipsis" title={item.payment_type}>{item.payment_type}</td>
										<td className="text-nowrap text-end">{formatMoney(item.amount)}</td>
									</tr>
								))}
							</tbody>
							<tfoot>
								<tr>
									<td><b>{t('total')}</b></td>
									<td className="text-nowrap text-end"><b>{formatMoney(payments.total_amount)}</b></td>
								</tr>
							</tfoot>
						</table>
					</div>
				</Modal.Body>
			</Modal>
			{/* MODAL PAYMENT HISTORY*/}
		</>
	)
}

export default Index