import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { Dropdown } from 'react-bootstrap'
import DatePicker from "react-datepicker"
import Select from 'react-select'

import FilterSearch from 'pages/parts/FilterSearch'
import FilterPagination from 'pages/parts/FilterPagination'

import { httpOk, FILE_D, GET } from 'helpers/api'
import { findKeyFromArrayByValue, formatMoney, regexPhoneNumber, returnSign, formatDate } from 'helpers/helpers'

function Index() {
	const { t } = useTranslation()
	const date = new Date()

	const [data, setData] = useState([])
	const [poses, setPoses] = useState([])
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	const [filterDataExist, setFilterDataExist] = useState(false)
	const [filterData, setFilterData] = useState({
		start_date: formatDate(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd'),
		end_date: formatDate(new Date(), 'yyyy-MM-dd'),
	})
	const [pagination, setPagination] = useState({
		url: '/cheques-by-clients',
		response: null
	})

	async function searchWithFilter() {
		var filterDataCopy = { ...filterData }
		var filterUrl = "/cheques-by-clients"
		var urlParams = ""
		checkFilter()

		if (filterDataCopy.pos_id)
			urlParams += returnSign(urlParams) + 'pos_id=' + filterDataCopy.pos_id
		if (filterDataCopy.start_date)
			urlParams += returnSign(urlParams) + 'start_date=' + filterDataCopy.start_date
		if (filterDataCopy.end_date)
			urlParams += returnSign(urlParams) + 'end_date=' + filterDataCopy.end_date
		if (filterDataCopy.search)
			urlParams += returnSign(urlParams) + 'search=' + filterDataCopy.search
		if (filterDataCopy.page)
			urlParams += returnSign(urlParams) + 'page=' + filterDataCopy.page
		filterDataCopy.urlParams = urlParams

		if (`/cheques-by-clients${urlParams}` !== filterUrl)
			setFilterDataExist(true)

		filterUrl = filterUrl + urlParams

		const response = await GET(filterUrl, {}, { loader: true })
		if (httpOk(response)) {
			setData(response.data)
			setPagination({ ...pagination, 'url': filterUrl, 'response': response.data })
		}

		setDropdownIsOpen(false)
	}

	async function downloadExcelFile() {
		var filterDataCopy = { ...filterData }
		var filterUrl = "/cheques-by-clients"
		var urlParams = "?excel=true"

		if (filterDataCopy.pos_id)
			urlParams += returnSign(urlParams) + 'pos_id=' + filterDataCopy.pos_id
		if (filterDataCopy.start_date)
			urlParams += returnSign(urlParams) + 'start_date=' + filterDataCopy.start_date
		if (filterDataCopy.end_date)
			urlParams += returnSign(urlParams) + 'end_date=' + filterDataCopy.end_date
		if (filterDataCopy.search)
			urlParams += returnSign(urlParams) + 'search=' + filterDataCopy.search

		filterUrl = filterUrl + urlParams
		var response = await FILE_D(filterUrl)
		
		if (httpOk(response)) {
			// Создаем объект Blob из полученного файла
			const file = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

			// Создаем ссылку для скачивания файла
			const fileUrl = URL.createObjectURL(file);

			// Создаем ссылку для скачивания файла в браузере
			const link = document.createElement('a');
			link.href = fileUrl;
			link.setAttribute('download', `${t('clients')}.xlsx`);
			document.body.appendChild(link);
			link.click();

			// Удаляем ссылку после скачивания файла
			URL.revokeObjectURL(fileUrl);
		}
	};

	function resetFilter() {
		setFilterData({
			pos_id: null,
			search: '',
		})
		searchWithFilter()
	}

	function checkFilter() {
		if (
			filterData.pos_id === null &&
			filterData.start_date === null &&
			filterData.end_date === null &&
			filterData.search === ''
		) {
			setFilterDataExist(false)
		}
	}

	function toggleDropdown() {
		setDropdownIsOpen(!dropdownIsOpen)
	}

	async function paginate(page) {
		setFilterData({ ...filterData, 'page': page })
	}

	async function getPoses() {
		const response = await GET('/helper/poses')
		if (httpOk(response)) {
			setPoses(response.data)
		}
	}

	useEffect(() => {
		getPoses()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		searchWithFilter(false)
	}, [filterData.search, filterData.page]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header">
				<h4 className="vertical-center h-100">
					{t('client_checks')} [{pagination?.response?.total}]
				</h4>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="d-flex justify-content-between flex-wrap mb-3">
						<div className="d-flex gap-2">
							<FilterSearch
								filterData={filterData}
								setFilterData={setFilterData}>
							</FilterSearch>
							<Dropdown onToggle={toggleDropdown} show={dropdownIsOpen}>
								<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100">
									{t('filter')}
								</Dropdown.Toggle>

								<Dropdown.Menu className="dropdown-filter">
									<div className="row">
										<div className="form-group col-md-6">
											<label>{t('pos')}</label>
											<Select
												options={poses}
												value={poses.find(option => option.id === filterData.pos_id) || ''}
												onChange={(option) => setFilterData({ ...filterData, 'pos_id': option.id })}
												placeholder=""
												noOptionsMessage={() => t('list_empty')}
												getOptionLabel={(option) => option.name}
												getOptionValue={(option) => option.id}
											/>
										</div>
									</div>
									<div className="row">
										<div className="form-group col-md-6">
											<label>{t('period')}</label>
											<div className="calendar-input">
												<DatePicker
													className="form-control"
													dateFormat="dd.MM.yyyy"
													selected={filterData.start_date ? new Date(filterData.start_date) : ''}
													onChange={(date) => setFilterData({ ...filterData, 'start_date': formatDate(date, 'yyyy-MM-dd') })} />
												<i className="uil uil-calendar-alt"></i>
											</div>
										</div>
										<div className="form-group col-md-6">
											<label className="invisible">.</label>
											<div className="calendar-input">
												<DatePicker
													className="form-control"
													dateFormat="dd.MM.yyyy"
													selected={filterData.end_date ? new Date(filterData.end_date) : ''}
													onChange={(date) => setFilterData({ ...filterData, 'end_date': formatDate(date, 'yyyy-MM-dd') })} />
												<i className="uil uil-calendar-alt"></i>
											</div>
										</div>
									</div>
									<div className="row mt-3">
										<div className="col-md-12">
											<div className="d-flex justify-content-end">
												<button className="btn btn-secondary me-2" onClick={() => setDropdownIsOpen(false)}>{t('cancel')}</button>
												<button className="btn btn-primary" onClick={() => searchWithFilter(false)}>{t('apply')}</button>
											</div>
										</div>
									</div>
								</Dropdown.Menu>
							</Dropdown>
							<div>
								<button type="button" className="btn btn-outline-primary btn-wide-2"
									onClick={() => downloadExcelFile()}>
									<i className="uil uil-file-download-alt"></i>
								</button>
							</div>
						</div>
						<div className="d-flex">
							<div className="vertical-center me-2">
								<h4><b>{t('total_amount')}</b>:</h4>
							</div>
							<div className="vertical-center">
								<h5 className="text-end">
									<span className={`me-1 ${data.sales_amount > 0 ? 'text-success' : 'text-danger'}`}>
										{formatMoney(data.sales_amount)}
									</span>
								</h5>
							</div>
						</div>
					</div>

					{filterDataExist &&
						<div className="filter-block">
							<div className="filter">
								<strong>{t('filter')}</strong>
								<br />
								{(filterData.start_date || filterData.end_date) &&
									<span className="me-2">
										<b>{t('period')}: </b>
										{filterData.start_date &&
											<span>{formatDate(filterData.start_date)}</span>
										}
										{(filterData.start_date && filterData.end_date) &&
											<span>|</span>
										}
										{filterData.end_date &&
											<span>{formatDate(filterData.end_date)}</span>
										}
									</span>
								}
								{filterData.pos_id &&
									<span className="me-2">
										<b>{t('pos')}: </b>
										{findKeyFromArrayByValue(poses, 'id', filterData.pos_id, 'name')}
									</span>
								}
								{filterData.search &&
									<span className="me-2">
										<b>{t('search')}: </b>
										<span>{filterData.search}</span>
									</span>
								}
							</div>
							<div className="vertical-center">
								<button className="btn btn-outline-dark text-uppercase" onClick={resetFilter}>{t('reset')}</button>
							</div>
						</div>
					}

					<div className="table-responsive">
						<table className="table table-bordered table-hover cursor mb-0">
							<thead>
								<tr>
									<th style={{ 'width': '33px' }} className="text-center">#</th>
									<th style={{ 'width': '20%' }}>{t('client')}</th>
									<th style={{ 'width': '10%' }}>{t("phone")}</th>
									<th style={{ 'width': '50%' }}></th>
									<th style={{ 'width': '10%' }} className="text-end">{t("quantity")}</th>
									<th style={{ 'width': '10%' }} className="text-end">{t("amount")}</th>
								</tr>
							</thead>
							<tbody>
								{data?.data?.map((item, index) => (
									<tr key={index}>
										<td className="text-center">{item.row_number}</td>
										<td className="td-ellipsis">{item.client_name}</td>
										<td>{regexPhoneNumber(item.client_phone)}</td>
										<td></td>
										<td className="text-end">{item.count_cheques}</td>
										<td className="text-end">
											{formatMoney(item.total_amount_service)}
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>

					{pagination.response &&
						<FilterPagination
							pagination={pagination}
							paginate={paginate}>
						</FilterPagination>
					}
				</div>
			</div>
		</>
	)
}

export default Index
