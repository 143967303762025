import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { Modal, Dropdown } from 'react-bootstrap'
import Select from 'react-select'

import { httpOk, GET, POST, PUT } from 'helpers/api'

function Index() {
	const { t } = useTranslation()

	const [data, setData] = useState([])
	const [orderTypes, setOrdertTypes] = useState([])
	const [selectedItem, setSelectedItem] = useState({})
	const [showModal, setShowModal] = useState(false)

	async function searchWithFilter() {
		var filterUrl = "/order-type-subs"
		var urlParams = ""

		filterUrl = filterUrl + urlParams

		const response = await GET(filterUrl, {}, { loader: true })
		if (httpOk(response)) setData(response.data)
	}

	async function createUpdate(e) {
		e.preventDefault()

		var response;
		if (selectedItem.id) {
			response = await PUT('/order-type-subs', selectedItem, { loader: true })
		} else {
			response = await POST('/order-type-subs', selectedItem, { loader: true })
		}

		if (httpOk(response)) {
			searchWithFilter()
			setShowModal(false)
		}
	}

	function toggleModal(bool = false, item = {}) {
		setSelectedItem(item)
		setShowModal(bool)
	}

	async function getOrderTypes() {
		const response = await GET('/helper/order-types')
		if (httpOk(response)) setOrdertTypes(response.data)
	}

	useEffect(() => {
		getOrderTypes()
		searchWithFilter()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between">
				<h4 className="vertical-center">
					{t('order_types')}
				</h4>
				<div className="d-flex">
					<button className="btn-rounded btn btn-primary"
						onClick={() => toggleModal(true)}>
						{t('create')}
					</button>
				</div>
			</div>

			<div className="card">
				<div className="card-body">

					<div className="table-responsive">
						<table className="table table-bordered mb-0">
							<thead>
								<tr>
									<th style={{ 'minWidth': '33px' }} className="text-center">#</th>
									<th style={{ 'width': '20%' }}>{t('name2')}</th>
									<th style={{ 'width': '20%' }}>{t('type')}</th>
									<th style={{ 'width': '45%' }}></th>
									<th style={{ 'width': '15%' }} className="text-center">{t('default')}</th>
									<th style={{ 'minWidth': '33px' }}></th>
								</tr>
							</thead>
							<tbody>
								{data?.map((item, index) => (
									<tr key={index}>
										<td className="text-center">{index + 1}</td>
										<td className="td-ellipsis">{item.name}</td>
										<td className="td-ellipsis">{item.order_type_name}</td>
										<td></td>
										<td className="text-center">
											{item.is_default ?
												<span>{t('yes')}</span>
												:
												<span>{t('no')}</span>
											}
										</td>
										<td>
											<div className="table-dropdown">
												<Dropdown id={`dropdown-${index}`}>
													<Dropdown.Toggle variant="outline-primary">
														<i className="uil uil-ellipsis-v"></i>
													</Dropdown.Toggle>

													<Dropdown.Menu>
														<div className="table-dropdown-item"
															onClick={() => toggleModal(true, item)}>
															<i className="uil-edit-alt"></i>
															{t('edit')}
														</div>
													</Dropdown.Menu>
												</Dropdown>
											</div>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>

				</div>
			</div>

			{/* MODAL */}
			<Modal show={showModal} animation={false} centered
				dialogClassName="update-modal-width" backdrop="static" onHide={() => toggleModal()}>
				<Modal.Header closeButton>
					<Modal.Title>
						{!selectedItem.id ?
							<>{t('create')}</>
							:
							<>{t('edit')}</>
						}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={createUpdate} autoComplete="off">
						<div className="form-group">
							<label>{t('name2')}<span className="required-mark">*</span></label>
							<input type="text" className="form-control" name="name"
								value={selectedItem.name}
								onChange={(e) => setSelectedItem({ ...selectedItem, 'name': e.target.value })} />
						</div>

						<div className="form-group">
							<label>{t('type')}<span className="required-mark">*</span></label>
							<Select
								isDisabled={selectedItem.id}
								options={orderTypes}
								value={orderTypes.find(option => option.id === selectedItem.order_type_id)}
								onChange={(option) => setSelectedItem({ ...selectedItem, 'order_type_id': option.id })}
								placeholder=""
								noOptionsMessage={() => t('list_empty')}
								getOptionLabel={(option) => option.name}
								getOptionValue={(option) => option.id}
							/>
						</div>

						<div className="form-check">
							<input id="is_default" className="form-check-input" type="checkbox"
								checked={selectedItem?.is_default ?? false}
								onChange={(e) => setSelectedItem({ ...selectedItem, 'is_default': e.target.checked })}
							/>
							<label className="form-check-label" htmlFor="is_default">
								{t('default')}
							</label>
						</div>

						<div className="d-flex gap-2 w-100 mt-3">
							<button type="button" className="btn btn-outline-warning w-100"
								onClick={() => toggleModal()}>
								{t('cancel')}
							</button>
							<button type="submit" className="btn btn-primary w-100">
								{t('save')}
							</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			{/* MODAL */}
		</>
	)
}

export default Index